import {
  PATHS,
  APP_TITLE,
  APPS,
  SECTIONS as SECTIONS_BY_MODULE,
} from 'variables';

export const PAGE_TITLES = {
  USERS: {
    title: `Users | ${APP_TITLE}`,
    heading: 'Users',
  },
  USER_GROUPS: {
    title: `User groups | ${APP_TITLE}`,
    heading: 'User groups',
  },
};

export const SECTIONS = {
  [PATHS.users_with_groups]: {
    key: PATHS.users_with_groups,
    title: PAGE_TITLES.USERS.heading,
    link: PATHS.users_with_groups,
    permissions: {
      app: APPS.administration,
      section: SECTIONS_BY_MODULE[APPS.administration].users,
    },
  },
  [PATHS.user_groups]: {
    key: PATHS.user_groups,
    title: PAGE_TITLES.USER_GROUPS.heading,
    link: PATHS.user_groups,
    permissions: {
      app: APPS.administration,
      section: SECTIONS_BY_MODULE[APPS.administration].groups,
    },
  },
};

export const ADD_USER_MODAL_MAX_WIDTH = 600;
export const EDIT_DELETE_BUTTONS_MAX_HEIGHT = 32;
export const DELETE_USER_MODAL_MAX_WIDTH = 420;
export const DETAILS_SECTION_PADDING = 20;

export const PERMISSIONS_TREE_MAP = {
  accountcard: 'Account and Card',
  'accountcard.activation': 'Activation',
  'accountcard.activation.activate': 'Activate',
  'accountcard.overview': 'Overview',
  'accountcard.transactions': 'Transactions',
  'accountcard.directDebits': 'Direct debits',
  'accountcard.limits': 'Limits',
  'accountcard.activityLog': 'Activity log',
  'accountcard.scaDevices': 'SCA devices',
  'accountcard.scaDevices.delete': 'Delete SCA devices',
  'accountcard.psdChallenges': 'PSD challenges',
  'accountcard.overview.issueAccount': 'Create account',
  'accountcard.overview.blockAccount': 'Block account',
  'accountcard.overview.closeAccount': 'Close account',
  'accountcard.overview.createRecipient': 'Create recipient',
  'accountcard.overview.issueCard': 'Issue card',
  'accountcard.overview.replaceCard': 'Replace card',
  'accountcard.overview.cardShipment': 'Get shipment info',
  'accountcard.overview.status': 'Change card status',
  'accountcard.overview.3ds': 'Enroll in 3DS',
  'accountcard.overview.pin': 'Clear PIN attempts',
  'accountcard.overview.editWallet': 'Manage wallet tokens',
  'accountcard.overview.audit': 'Lock card for audit',
  'accountcard.overview.transfer': 'Transfer to third-party account',
  'accountcard.overview.cardpayin': 'Card pay-in',
  'accountcard.overview.orderplasticcard': 'Order plastic card',
  'accountcard.overview.requestTransfer': 'Request an operational transfer',
  'accountcard.transactions.export': 'Export transactions',
  'accountcard.transactions.cancel': 'Cancel transactions',
  'accountcard.transactions.chargeback': 'Chargeback transactions',
  'accountcard.transactions.fraud': 'Mark as potential fraud',
  'accountcard.transactions.requestTransfer': 'Request an operational transfer',
  'accountcard.transactions.returnSelfTransfer': 'Issue a transfer return',
  'accountcard.directDebits.enable': 'Enable / disable direct debits',
  'accountcard.directDebits.cancelMandate': 'Cancel direct debit mandate',
  'accountcard.limits.edit': 'Update limits',
  'accountcard.scheduledPayments': 'Scheduled payments',
  'accountcard.scheduledPayments.cancelSchedule': 'Cancel a schedule',
  'accountcard.scheduledPayments.skipNextPayment':
    'Skip next payment of a schedule',
  'accountcard.overview.lockAudit': 'Lock card for audit',
  'accountcard.overview.unlockAudit': 'Unlock card from audit',
  mcReport: 'Reports',
  'mcReport.generate': 'Generate MasterCard fraud report',
  users: 'Users',
  groups: 'Groups',
  'users.edit': 'Edit users',
  'users.riskprofile': 'Risk profile',
  'groups.edit': 'Edit groups',
  actions: 'Actions',
  authorizations: 'Authorizations',
  clearings: 'Clearings',
  'authorizations.adjust': 'Adjust authorizations',
  'clearings.match': 'Match clearings',
  'clearings.reprocess': 'Reprocess clearings',
  'jobs.run': 'Run jobs',
  jobs: 'Jobs',
  cancelauthorizations: 'Authorizations for cancellation',
  'cancelauthorizations.cancel': 'Cancel Authorizations',
  requests: 'Operational transfers',
  'requests.initiate': 'Initiate, edit & cancel',
  'requests.preApproveAndAuthorize': 'Pre-approve, authorize & reject',
  'actions.publishPaymentEvents': 'Mass publish payment events',
};

export const APP_CODES = {
  MERCHANT: 'Merchant',
  REPORTS: 'Reports',
  ADMINISTRATION: 'Administration',
  MASS_ACTIONS: 'Mass actions',
};
